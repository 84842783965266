.craft-switcher {
  --mdc-switch-selected-track-color: #0064ff;
  --mdc-switch-selected-hover-handle-color: #0064ff;
  --mdc-switch-selected-focus-handle-color: #0064ff;
  --mdc-switch-selected-pressed-handle-color: #0064ff;
  --mdc-switch-selected-focus-track-color: #0064ff;
  --mdc-switch-selected-hover-track-color: #0064ff;
  --mdc-switch-selected-pressed-track-color: #0064ff;
  --mdc-switch-selected-handle-color: #0064ff;
  --mdc-switch-disabled-selected-handle-color: #bdbdbd;
  --mdc-switch-disabled-selected-track-color: #bdbdbd;
  --mdc-switch-unselected-handle-color: #fff;
  --mdc-switch-unselected-hover-handle-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #fff;
  --mat-slide-toggle-label-text-size: 15px;

  .mdc-form-field {
    column-gap: 8px;
  }

  .mdc-switch {
    --mdc-switch-track-height: 3px;
    --mdc-switch-track-width: 23px;
    --mdc-switch-handle-width: 15px;
    --mdc-switch-handle-height: 15px;
    --mdc-switch-disabled-handle-opacity: 0.38;
    --mdc-switch-disabled-selected-icon-opacity: 0.38;
    --mdc-switch-disabled-track-opacity: 0.38;
    --mdc-switch-disabled-unselected-icon-opacity: 0.38;
    --mdc-switch-handle-surface-color: #0064ff;
    &.mdc-switch--disabled {
      --mdc-switch-handle-surface-color: #bdbdbd;
    }
  }

  .mdc-switch__handle {
    &::after {
      border: 1px solid #bdbdbd;
    }
  }
  .mdc-switch__icons,
  .mdc-switch__shadow,
  .mdc-switch__ripple {
    display: none;
  }
  &.mat-mdc-slide-toggle-checked {
    .mdc-switch__handle {
      right: -8px;
      left: unset;

      &::after {
        border: none;
      }
    }
  }

  &.sm {
    .mdc-switch {
      --mdc-switch-track-width: 17px;
      --mdc-switch-handle-width: 11px;
      --mdc-switch-handle-height: 11px;
    }
  }
}
