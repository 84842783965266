// Colors
$white: #fff;
$black: #212121;
$sky-blue: #0091ff;
$active-blue: #0064ff;
$action-blue: #0479ff;
$light-blue: #dceeff;
$blue-gray: #9194b7;
$dark-gray: #65778b;
$sub-gray: #7e80a8;
$gray: #acacac;
$devider-gray: #e5e5e5;
$light-gray: #f6f6f6;
$selected-gray: #f5f5f5;
$silver: #bdbdbd;
$purple: #9d00ef;
$rose: #ea3ac1;
$attention-red: #e91e63;
$warn: #e91e63;
$cyan: #00c6c5;
$green: #1ec933;
$orange: #f59d00;
$default-border: #f1f1f1;

$mat-blue: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #0064ff,
  500: #2196f3,
  600: #1e88e5,
  700: #1976d2,
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: #2962ff,
    100: #2962ff,
    200: #2962ff,
    300: #2962ff,
    400: #2962ff,
    500: #2962ff,
    600: #2962ff,
    700: #2962ff,
    800: #2962ff,
    900: #2962ff,
    A100: #2962ff,
    A200: #2962ff,
    A400: #2962ff,
    A700: #2962ff,
  ),
);
