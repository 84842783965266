.mat-calendar {
  html & {
    --mat-datepicker-calendar-date-hover-state-background-color: transparent;
    --mat-datepicker-calendar-date-focus-state-background-color: transparent;
  }

  .mat-calendar-body-today {
    border: none;
    font-weight: 600;
  }

  .mat-calendar-body-cell {
    &:not(.mat-calendar-body-disabled) {
      &:hover {
        > .mat-calendar-body-cell-content {
          &:not(.mat-calendar-body-selected),
          &:not(.mat-calendar-body-comparison-identical) {
            color: $active-blue;
          }
          &.mat-calendar-body-selected {
            color: $white;
          }
        }
      }
    }
  }

  .mat-calendar-body-selected {
    background-color: $active-blue;
    color: $white;
  }

  .mat-calendar-body-in-range {
    &::before {
      background-color: $light-blue;
    }
  }
}

.mat-datepicker-toggle {
  display: flex;

  &:not(.mat-datepicker-toggle-active) {
    color: var(--mdc-checkbox-unselected-icon-color);
  }

  .mat-mdc-icon-button {
    --mdc-icon-button-icon-size: 16px;
    color: inherit;
    &.mat-mdc-button-base {
      --mdc-icon-button-state-layer-size: 16px;
      padding: 0;
    }

    .mat-mdc-button-touch-target {
      width: 20px;
      height: 20px;
    }

    [matdatepickertoggleicon] {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .mdc-icon-button__ripple {
    display: none;
  }
}
