.mat-mdc-nav-list {
  margin-top: 22px;
  padding-top: 0;
  .mat-mdc-list-item {
    .mdc-list-item__primary-text {
      font-size: 16px;
      font-weight: 500;
      color: $black;

      .link.disabled {
        color: $silver;
        pointer-events: none;
      }
    }

    &:hover,
    &.active {
      &::before {
        background-color: transparent;
      }
    }
    &.active {
      .mdc-list-item__primary-text {
        color: $sky-blue;
        font-weight: 700;
      }
    }
  }
}
