.highcharts-tooltip-container {
  svg.highcharts-root {
    .top-reporter-tooltip {
      display: none;
    }
  }

  .highcharts-tooltip {
    &.top-reporter-tooltip {
      > span {
        padding: 10px;
        width: fit-content !important;
        border-radius: 4px;
        background-color: $active-blue;
        position: relative;
        margin-left: -8px !important;
        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-width: 8px;
          border-style: solid;
          border-color: transparent $active-blue transparent transparent;
          top: calc(50% - 8px);
          left: -14px;
        }
      }
    }

    .break {
      word-wrap: break-word;
      max-width: 100%;
    }
  }
}

.craft-bar-chart {
  .highcharts-data-labels {
    width: calc(100% - 5px);

    .highcharts-data-label {
      width: 100%;
      left: 0 !important;
      > span {
        width: 100% !important;
      }
    }
  }
}
