// Change tooltip
.mat-tooltip {
  background: $active-blue !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.svg-icon {
  position: relative;
  display: inline-flex;
  align-items: center;
}

craft-root {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.mat-form-field.mat-focused .mat-form-field-underline .mat-form-field-ripple {
  background-color: $active-blue;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-underline .mat-form-field-ripple {
  background-color: $attention-red;
}
