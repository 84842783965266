.mat-button-toggle-group {
  --mat-standard-button-toggle-height: 20px;

  .mat-button-toggle {
    min-width: 30px;
    text-align: center;
    .mat-button-toggle-label-content {
      padding: 0 4px;
    }
  }
}
