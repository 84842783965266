@import 'mixins/vars';

.craft-tags-field {
  .mdc-evolution-chip-set {
    .mdc-evolution-chip-set__chips {
      margin: 0;
      gap: 4px;
      overflow: visible;
    }

    .mdc-evolution-chip {
      --mdc-chip-container-height: 23px;
      margin: 0;
      border: 1px solid $devider-gray;
      border-radius: 4px;
    }
  }

  .mat-mdc-standard-chip {
    --mdc-chip-disabled-label-text-color: $dark-gray;
    --mdc-chip-elevated-container-color: transparent;
    --mdc-chip-elevated-disabled-container-color: $light-gray;
    --mdc-chip-label-text-color: $dark-gray;
    --mdc-chip-with-icon-icon-color: $dark-gray;
    --mdc-chip-with-icon-disabled-icon-color: $dark-gray;
    --mdc-chip-with-icon-selected-icon-color: $dark-gray;
    --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: $dark-gray;
    --mdc-chip-with-trailing-icon-trailing-icon-color: $dark-gray;
    &.mdc-evolution-chip--with-trailing-action {
      .mdc-evolution-chip__action--trailing {
        padding: 0 4px;
      }
    }
  }

  .mdc-text-field {
    padding: 0;
  }
  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }
  .mat-mdc-form-field-infix {
    min-height: unset;
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) {
    .mat-mdc-form-field-infix {
      padding-top: 12px;
      padding-bottom: 6px;
    }
  }
}
