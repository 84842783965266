.mat-mdc-form-field-type-mat-input {
  --mdc-filled-text-field-disabled-container-color: transparent;
  .mdc-text-field {
    padding: 0;
  }
  .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }
}

html {
  --mdc-filled-text-field-container-shape: 0;
  --mdc-filled-text-field-container-color: transparent;
}
