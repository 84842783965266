// Fix default box-sizing
*,
:before,
:after {
  box-sizing: border-box;
}

// Fix document height
html,
body {
  height: 100%;
}

// Fix height and scrollbars
body {
  color: $black;
  font-family: proxima-soft, sans-serif;
  position: relative;

  // Remove default margins
  margin: 0;
  padding: 0;

  // Fix hyphenation
  word-wrap: break-word;

  // Fix rendering fonts
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Fix text zoom on Mobile Devices
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  // Fix tap color
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  // Remove 300ms delay on Mobile Devices
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

// Remove default styles
a,
input,
button,
textarea {
  font-family: inherit;
  color: inherit;
  line-height: inherit;
  text-transform: inherit;
  text-shadow: inherit;
  border: none;
  background: none;
  box-shadow: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
}

// Remove default text-decoration, fix cursor
a {
  cursor: pointer;
  text-decoration: none;

  // Remove gaps in links underline in iOS 8+ and Safari 8+
  -webkit-text-decoration-skip: none;
}

// Fix button bugs
button {
  overflow: visible;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
}

// Remove default margins, add hyphenation, add correct highlight
p {
  margin: 0;
  overflow: hidden;
}

// Fix font-weight
b,
strong {
  font-weight: 700;
}

// Fix Firefox transform scale image bug
img {
  image-rendering: optimizeQuality;
}

// Add valid default styles
h1,
h2,
h3,
h4,
h5,
h6 {
  text-rendering: optimizeLegibility;
  font: inherit;
  color: inherit;
  margin: 0;
}

// Change the fill color to match the text color in all browsers
svg {
  color: inherit;

  // Hide the overflow in IE
  &:not(:root) {
    overflow: hidden;
  }
}

// Fix default alignment
progress {
  vertical-align: baseline;
}

// Add valid default styles
h1,
h2,
h3,
h4,
h5,
h6 {
  text-rendering: optimizeLegibility;
  font: inherit;
  color: inherit;
  margin: 0;
}

// Remove user-select and add cursor pointer on button
button,
label,
select,
summary,
[type='button'],
[type='submit'],
[type='reset'],
[type='checkbox'],
[type='radio'],
[type='range'] {
  user-select: none;
  cursor: pointer;
}

// Fix appearance in Chrome and Safari
[type='button'],
[type='submit'],
[type='search'],
[type='radio'],
[type='range'],
::-webkit-search-cancel-button,
::-webkit-search-decoration,
::-webkit-outer-spin-button,
::-webkit-inner-spin-button,
::-webkit-slider-thumb {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

// Hide autofill Safari icons in input field
::-webkit-contacts-auto-fill-button,
::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

// Fix default autocomplete background color in Chrome and Safari
:-webkit-autofill {
  box-shadow: inset 0 0 0 1000px #fff;
}

// Remove summary arrow
::-webkit-details-marker {
  display: none;
}

// Fix appearance in Mobile Safari
[type='radio'] {
  appearance: radio;
}

// Fix appearance in Firefox
[type='number'] {
  appearance: textfield;
}

// Add scrollbars, remove resize
textarea {
  overflow: auto;
  resize: none;
}

// Remove outline on the forms and links
:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}

// Remove click on the disabled state
:disabled {
  pointer-events: none;

  // Fix disabled text color
  -webkit-text-fill-color: inherit;
}

// Remove border and padding in Firefox
::-moz-focus-outer,
::-moz-focus-inner {
  border: 0;
  padding: 0;
}

// Fix placeholder color
::placeholder {
  opacity: 1;
  color: $silver;
  &:active {
    opacity: 0;
  }
}
::-moz-placeholder {
  opacity: 1;
  color: $silver;
  &:active {
    opacity: 0;
  }
}

// Remove default spacing
table {
  border-spacing: 0;
  border-collapse: collapse;
}

// Remove default padding
td,
th {
  padding: 0;
  text-align: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}
