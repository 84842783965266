.portal-header-wrap {
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #f1f1f1;
  background: #fff;
}

.portal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1380px;
  height: 64px;
  padding: 0 12px;
  position: relative;
  @include media('>=sm') {
    padding: 0 24px;
  }
  craft-portal-logo {
    display: block;
    max-width: 75px;
    overflow: hidden;
    @include media('>=sm') {
      max-width: 188px;
    }
    img {
      max-width: 100%;
      max-height: 42px;
      display: block;
    }
  }
}

.portal-header-project {
  display: flex;
  align-items: center;
  @include media('>=sm') {
    width: 25%;
  }
}

.portal-name {
  font-weight: 700;
  &.small {
    font-size: 17px;
    line-height: 19px;
  }
  &.big {
    font-size: 25px;
    line-height: 25px;
  }

  @include media('<sm') {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 188px;
  }
}

.portal-open-menu {
  margin: 0 12px 0 0;
  height: 18px;
  @include media('>=sm') {
    margin: 0 24px 0 0;
  }
  @include media('>=desktop') {
    display: none;
  }
}

.portal-header-name {
  display: inline-block;
  text-align: center;
  font-size: 25px;
  line-height: 30px;
  width: calc(100% - 150px);
  color: $dark-gray;
  @include media('>=sm') {
    width: 50%;
  }
  .mdc-button {
    padding: 0 6px;
  }
  span.mdc-button__label {
    font-size: 18px;
    font-weight: 400;
    display: inline-block;
    @extend %ellipsis;
  }
}

.portal-header-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include media('>=sm') {
    width: 25%;
  }
  .item-btn {
    display: flex;
    align-items: center;
    justify-self: center;
  }
  .mdc-button {
    padding: 0 8px;
    margin: 0;
    min-width: 32px;
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-self: center;
    .mdc-button__label {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
