@mixin h-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

@mixin v-ellipsis($lines: 2) {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

%align {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

%align-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

%align-middle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

%extend {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

%extend-center {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

%extend-middle {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

%ellipsis {
  @include h-ellipsis;
}

%v-ellipsis {
  @include v-ellipsis(2);
}

%clear {
  clear: both;
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

%before {
  content: '';
  position: absolute;
  z-index: 1;
}

%status-circle {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 50%;
}
