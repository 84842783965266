@import 'mixins/vars';

.mat-mdc-radio-button {
  .mdc-form-field {
    column-gap: 10px;
    .mdc-radio {
      --mdc-radio-state-layer-size: 16px;
      width: 16px;
      height: 16px;
      padding: 0;
      display: flex;

      .mat-mdc-radio-touch-target {
        width: 20px;
        height: 20px;
      }

      .mdc-radio__background {
        width: 16px;
        height: 16px;
        &::before {
          top: calc(-1 * (var(--mdc-radio-state-layer-size) - 16px) / 2);
          left: calc(-1 * (var(--mdc-radio-state-layer-size) - 16px) / 2);
        }
      }
    }
  }

  .mdc-radio__outer-circle {
    border-width: 1px;
  }

  .mdc-radio__inner-circle {
    width: 50%;
    height: 50%;
    border-width: 8px;
  }

  .mdc-label {
    padding: 0;
    margin: 0;
    display: inline-flex;
    align-items: center;
    column-gap: 10px;
  }

  &:hover {
    .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
      border-color: var(--mdc-radio-unselected-hover-icon-color);
    }
  }

  &.mat-mdc-radio-checked,
  &:hover {
    .mdc-label {
      color: $black;
      .svg-icon {
        color: $black;
      }
    }
  }

  &.radio-btn-blue {
    --mdc-radio-unselected-hover-icon-color: #0064ff;
    &.mat-mdc-radio-checked,
    &:hover {
      .mdc-label {
        color: $active-blue;
        .svg-icon {
          color: $active-blue;
        }
      }
    }
  }
}
